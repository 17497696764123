//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import CookieBanner from '../components/lazyloads/dialogs/CookieBanner.vue'
import LazyLoginUserDialog from '~/components/lazyloads/dialogs/LazyLoginUserDialog.vue'
import PWADialog from '~/components/lazyloads/dialogs/pwaDialog.vue'

export default {
  components: {
    LazyLoginUserDialog,
    CookieBanner,
    PWADialog,
  },
  mounted() {
    // if a user has been found, log them in
    if (this.$route.query.revisit) {
      this.$refs.dialog.openDialog(this.$route.query.revisit)
    }
  },
}
