//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: 'LoginUserDialog',
  data: () => ({
    code: null,
    dialog: false,
  }),
  methods: {
    async loginUser() {
      if (!this.code || this.code.length <= 0) {
        this.$dialog.message.info('Please enter your access code.', {
          position: 'top',
          color: 'red',
        })
        return
      }

      await this.$store
        .dispatch('auth/login_user', {
          password: this.code.trim().toLowerCase(),
        })
        .then((response) => {})
        .catch(() => {
          this.$dialog.message.error('Sorry, we dont recognise this user!', {
            position: 'top',
            color: 'red',
            timeout: 2000,
          })
        })
    },
  },
}
